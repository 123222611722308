.general-overview-layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
//   overflow-y: scroll;

  .header-wrap {
    @media only screen and (max-width: 63em) {
      position: relative;
      z-index: 20;
     }
  }

  .main-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 8rem);
    
    @media only screen and (max-width: 63em) {
      position: relative;
      z-index: 10;
     }

    
  }

  .footer-wrap {
    margin: 5rem 0rem;
    // justify-self: flex-end;

    @media only screen and (max-width: 40em) {
     margin-top: 2rem;
    }

    .size-wrap {
      .logo-text-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem;

        @media only screen and (max-width: 63em) {
         flex-direction: column;
         gap: 2rem;
        }

        .img-box {
          width: 8rem;
          height: 3.5rem;
          cursor: pointer;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .text {
          color: rgba(69, 90, 100, 1);
          font-size: 1.3rem;
        }
      }

      .list-link-box {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-gap: 2rem;

        @media only screen and (max-width: 63em) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 3rem;
        }

        .list-box {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          // background-color: red;

          .list-item {
            font-size: 1.3rem;
            cursor: pointer;
          }

          .list-item-active {
            font-weight: 700;
            cursor: unset;
          }

          .link-item-active{
            color: rgba(0, 75, 255, 1);
            font-weight: 500;
          }
        }
      }
    }
  }
}
