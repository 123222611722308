.faq-section-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5rem 0rem;
}
.faq-section-wrap .size-wrap {
  display: flex;
  flex-direction: column;
}
.faq-section-wrap .size-wrap .section-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: rgb(0, 75, 255);
  align-self: center;
  border-bottom: 0.15rem solid currentColor;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.faq-section-wrap .size-wrap .section-title:hover {
  transform: scale(1.03);
}
.faq-section-wrap .size-wrap .faq-title {
  font-weight: 600;
  font-size: 2.6vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
@media only screen and (max-width: 40em) {
  .faq-section-wrap .size-wrap .faq-title {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}
.faq-section-wrap .size-wrap .faq-content-wrap .faq-body-wrap {
  display: flex;
  justify-content: center;
}
.faq-section-wrap .size-wrap .faq-content-wrap .faq-body-wrap .content-wrap {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 50%;
}
@media only screen and (max-width: 40em) {
  .faq-section-wrap .size-wrap .faq-content-wrap .faq-body-wrap .content-wrap {
    width: 100%;
  }
}/*# sourceMappingURL=FaqSection.css.map */