.trust-section-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  // @media only screen and (max-width: 63em) {
  //   display: none;
  // }

  .video-bg {
    background-color: rgba(251, 251, 251, 1);
    padding: 5rem 0rem;
    display: flex;

    .size-wrap {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      // grid-gap: 5rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3rem;

      .img-wrap {
        .img-box {
          width: 10rem;
          height: 8rem;  

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      // & > *:nth-child(2) {
      //   .img-box {
      //    transform: scale(1.7);
      //   }
      // }
    }
  }

  //   .down-text{
  //     font-size: 3.5rem;
  //     text-align: center;
  //     margin-top: 1.5rem;
  //   }
}
