.support-hero-section-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 15rem 0rem 5rem 0rem;
}
@media only screen and (max-width: 40em) {
  .support-hero-section-wrap {
    width: 90%;
    margin: 15rem auto 5rem auto;
  }
}
.support-hero-section-wrap .page-title {
  font-size: 3.5vw;
  font-weight: 500;
  line-height: 6rem;
  font-family: "Lexend", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 40em) {
  .support-hero-section-wrap .page-title {
    font-size: 3rem;
    line-height: 4.5rem;
  }
}
.support-hero-section-wrap .two-button-wrap {
  margin-top: 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}
.support-hero-section-wrap .two-button-wrap .second-btn {
  background-color: rgba(14, 115, 246, 0.1);
}
.support-hero-section-wrap .two-button-wrap .second-btn .text {
  color: #0e73f6;
}
.support-hero-section-wrap .small-text {
  text-align: center;
  font-size: 1.45rem;
  font-family: "Lexend", sans-serif;
  width: 50%;
  line-height: 1.9rem;
  color: rgb(91, 104, 113);
}
@media only screen and (max-width: 40em) {
  .support-hero-section-wrap .small-text {
    font-size: 1.65rem;
    line-height: 2.5rem;
    width: 100%;
    text-align: justify;
  }
}/*# sourceMappingURL=HeroSection.css.map */