.privacy-hero-section-wrap {
    //   min-height: 40rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      align-items: center;
      justify-content: center;
      padding: 15rem 0rem 5rem 0rem;
      background-color: rgba(42, 46, 164, 0.05);

      @media only screen and (max-width: 50em) {
        // width: 90%;
        padding: 15rem calc(100vw - 95%) 5rem calc(100vw - 95%);
       }
    
      .page-title {
        font-size: 3.5vw;
        font-weight: 500;
        line-height: 5rem;
        font-family: "Lexend", sans-serif;
        text-align: center;

        @media only screen and (max-width: 40em) {
          font-size: 3rem;
         }
      }
    
      .two-button-wrap {
        margin-top: 2rem;
        display: flex;
        gap: 3rem;
        align-items: center;
    
        .second-btn {
          background-color: rgba(14, 115, 246, 0.1);
    
          .text {
            color: #0e73f6;
          }
        }
      }
    
      .small-text {
        text-align: center;
        font-size: 1.45rem;
        font-family: "Lexend", sans-serif;
        width: 60%;
        line-height: 1.9rem;
        color: rgba(91, 104, 113, 1);

        @media only screen and (max-width: 40em) {
          font-size: 1.65rem;
          line-height: 2.2rem;
          width: 100%;
          text-align: justify;
         }
      }
    }
    