.ultimate-section-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  .section-title {
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .content-down-wrap {
    background-color: rgba(239, 242, 252, 1);

    .size-wrap {
      display: flex;
      flex-direction: column;
      padding-top: 8rem;
      padding-bottom: 8rem;
      gap: 7rem;

      @media only screen and (max-width: 63em) {
        padding-top: 4rem;
        padding-bottom: 4rem;
        gap: 4rem;
      }
    }

    .picture-text-wrap {
      display: flex;
      min-height: 35rem;
      gap: 5rem;

      @media only screen and (max-width: 63em) {
        flex-direction: column;
        width: 100%;
        gap: 2.5rem;
        min-height: unset;
        // min-height: 50rem;
      }

      .img-content {
        flex: 0 0 50%;
        display: grid;
        place-items: center;

        @media only screen and (max-width: 63em) {
          flex: unset;
          width: 100%;
          height: 25rem;
          order: 1;
        }

        .content {
          width: 100%;
          height: 100%;
          background-image: url("../../assets/revex-component-img.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          // @media only screen and (max-width: 63em) {
          //   // min-height: 25rem;
          // }
        }

        .img-one {
          background-image: url("../../assets/empower-ultimate-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-two {
          background-image: url("../../assets/save-time-section-img.png");
        }

        .img-three {
          background-image: url("../../assets/customize-section.png");
        }

        .img-four {
          background-image: url("../../assets/real-ultimate-img.png");
        }

        .img-one-b {
          background-image: url("../../assets/intuitive-ultimate-img.png.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-two-b {
          background-image: url("../../assets/business-intelligence-report-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-three-b {
          background-image: url("../../assets/automation-ultimate-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-four-b {
          background-image: url("../../assets/customizable-budget-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-five-b {
          background-image: url("../../assets/real-time-ultimate-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-six-b {
          background-image: url("../../assets/expenditure-ultimate-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }

        .img-seven-b {
          background-image: url("../../assets/revenue-ultimate-img.png");
          // background-color: rgba(253, 252, 235, 1);
        }
      }

      .content-text-wrap {
        flex: 1;
        gap: 1rem;
        margin-top: 1rem;

        @media only screen and (max-width: 63em) {
          flex-direction: column;
          width: 100%;
          flex: unset;
          // min-height: 25rem;
        }

        .big-text-wrap {
          .big-text {
            font-size: 2.5rem;

            @media only screen and (max-width: 63em) {
              text-align: center;
            }
          }
        }

        .small-text-wrap {
          width: 70%;

          @media only screen and (max-width: 63em) {
            width: 100%;
          }

          .small-text {
            font-size: 1.45rem;

            @media only screen and (max-width: 63em) {
              text-align: center;
              font-size: 1.65rem;
            }

            @media only screen and (max-width: 40em) {
              text-align: justify;
            }
          }
        }
      }
    }

    .picture-text-wrap-two {
      flex-direction: column;
      margin-bottom: 5rem;

      .img-content {
        flex: unset;
        width: 80%;
        margin: 0rem auto;

        .content {
          background-image: url("../../assets/seamless-integration-img.png");
          min-height: 40rem;
          background-size: cover;
        }
      }

      .content-text-wrap {
        margin-top: unset;
        flex: unset;
        width: 100%;

        .big-text-wrap {
          .big-text {
            font-size: 2.5rem;
            text-align: center;

            @media only screen and (max-width: 63em) {
              text-align: center;
            }
          }
        }

        .small-text-wrap {
          width: 70%;
          margin: 0rem auto;

          @media only screen and (max-width: 63em) {
            width: 100%;
          }

          .small-text {
            font-size: 1.45rem;
            text-align: center;

            @media only screen and (max-width: 63em) {
              text-align: center;
              font-size: 1.65rem;
            }

            @media only screen and (max-width: 40em) {
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
