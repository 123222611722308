// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 1.4rem;
  //   font-family: "Outfit", sans-serif;
  // font-family: 'Lexend', sans-serif;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overscroll-behavior: none;
  color: #000000;
  overflow-x: hidden;
  background-color: #ffffff;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #004bff;
  }
}

// auto fill removal

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bg-white-color) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--bg-white-color) inset !important;
  // background-color: var(--bg-white-color);
}

.size-wrap {
  width: 85%;
  margin: 0rem auto;

  @media only screen and (max-width: 63em) {
    width: 90%;
  }
}

.heading-one {
  font-size: 3rem;
  color: 600;

  @media only screen and (max-width: 63em) {
  }
}

.heading-two {
  font-size: 2.5rem;
  font-weight: 500;
}

.heading-three {
  font-size: 2.2rem;
  font-weight: 500;
}

.content-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .big-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .big-text {
      font-weight: 600;
      font-size: 2rem;

      @media only screen and (max-width: 63em) {
        font-size: 2.3rem;
      }
    }
  }

  .small-text-wrap {
    .small-text {
      font-size: 1.5rem;
      color: #5b6871;
      line-height: 1.85rem;

      @media only screen and (max-width: 63em) {
        font-size: 1.9rem;
        line-height: 2.5rem;
      }
    }
  }
}

.parent-modal > div > div > div {
  background-color: transparent !important;
  box-shadow: unset !important;
}

.parent-modal > div > div > div:nth-child(2) {
  backdrop-filter: blur(0.5rem);
  z-index: 500;
  /* background-color: red; */
}

.table-feature-container-to-control-mobile {
  @media only screen and (max-width: 40em) {
    width: 33rem;
    padding: 0 2rem;
    overflow-x: scroll;
  }
}



.comparison-oval-loader {
  display: flex;
  justify-content: center;
}
// mobile-card for comparison component

// .mobile-comparison-card-view{
//   padding: 0 5rem;
//   .mobile-comparison-card-box{
//   //   display: flex;
//   // flex-direction: column;
//   }
//   .header-card-wrap{
//     display: flex;
//     justify-content: space-between;
//   }
//   .feature-card-wrap{
//     display: flex;
//     flex-direction: column;
//   }
//   .feature-details-each{
//     display: flex;
//     justify-content: space-between;
//   }
// }
