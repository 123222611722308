.ultimate-section-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.ultimate-section-wrap .section-title {
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.ultimate-section-wrap .content-down-wrap {
  background-color: rgb(239, 242, 252);
}
.ultimate-section-wrap .content-down-wrap .size-wrap {
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  padding-bottom: 8rem;
  gap: 7rem;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .size-wrap {
    padding-top: 4rem;
    padding-bottom: 4rem;
    gap: 4rem;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap {
  display: flex;
  min-height: 35rem;
  gap: 5rem;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap {
    flex-direction: column;
    width: 100%;
    gap: 2.5rem;
    min-height: unset;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content {
  flex: 0 0 50%;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content {
    flex: unset;
    width: 100%;
    height: 25rem;
    order: 1;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .content {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/revex-component-img.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-one {
  background-image: url("../../assets/empower-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-two {
  background-image: url("../../assets/save-time-section-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-three {
  background-image: url("../../assets/customize-section.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-four {
  background-image: url("../../assets/real-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-one-b {
  background-image: url("../../assets/intuitive-ultimate-img.png.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-two-b {
  background-image: url("../../assets/business-intelligence-report-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-three-b {
  background-image: url("../../assets/automation-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-four-b {
  background-image: url("../../assets/customizable-budget-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-five-b {
  background-image: url("../../assets/real-time-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-six-b {
  background-image: url("../../assets/expenditure-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .img-content .img-seven-b {
  background-image: url("../../assets/revenue-ultimate-img.png");
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap {
  flex: 1;
  gap: 1rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap {
    flex-direction: column;
    width: 100%;
    flex: unset;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .big-text-wrap .big-text {
  font-size: 2.5rem;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .big-text-wrap .big-text {
    text-align: center;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .small-text-wrap {
  width: 70%;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .small-text-wrap {
    width: 100%;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
  font-size: 1.45rem;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
    text-align: center;
    font-size: 1.65rem;
  }
}
@media only screen and (max-width: 40em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
    text-align: justify;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two {
  flex-direction: column;
  margin-bottom: 5rem;
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .img-content {
  flex: unset;
  width: 80%;
  margin: 0rem auto;
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .img-content .content {
  background-image: url("../../assets/seamless-integration-img.png");
  min-height: 40rem;
  background-size: cover;
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap {
  margin-top: unset;
  flex: unset;
  width: 100%;
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .big-text-wrap .big-text {
  font-size: 2.5rem;
  text-align: center;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .big-text-wrap .big-text {
    text-align: center;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .small-text-wrap {
  width: 70%;
  margin: 0rem auto;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .small-text-wrap {
    width: 100%;
  }
}
.ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .small-text-wrap .small-text {
  font-size: 1.45rem;
  text-align: center;
}
@media only screen and (max-width: 63em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .small-text-wrap .small-text {
    text-align: center;
    font-size: 1.65rem;
  }
}
@media only screen and (max-width: 40em) {
  .ultimate-section-wrap .content-down-wrap .picture-text-wrap-two .content-text-wrap .small-text-wrap .small-text {
    text-align: justify;
  }
}/*# sourceMappingURL=UltimateSection.css.map */