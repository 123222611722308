.contact-support-visit-wrap {
  background-color: rgb(239, 242, 252);
  padding: 5rem 0rem;
  display: flex;
}
.contact-support-visit-wrap .size-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 5rem;
  align-self: center;
}
.contact-support-visit-wrap .size-wrap .item-wrap {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
}
.contact-support-visit-wrap .size-wrap .item-wrap .text-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contact-support-visit-wrap .size-wrap .item-wrap .text-wrap .title {
  font-size: 1.55rem;
  font-weight: 600;
}
.contact-support-visit-wrap .size-wrap .item-wrap .text-wrap .text {
  font-size: 1.45rem;
  width: 65%;
  color: rgb(91, 104, 113);
  line-height: 2rem;
}
.contact-support-visit-wrap .size-wrap .item-wrap .text-wrap .link {
  color: rgb(0, 75, 255);
  border-bottom: 0.1rem solid rgb(0, 75, 255);
  align-self: flex-start;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.3rem;
  font-weight: 500;
}
.contact-support-visit-wrap .size-wrap .item-wrap .text-wrap .link:hover {
  transform: scale(1.03);
}
.contact-support-visit-wrap .size-wrap .item-wrap .logo-box .img-box {
  width: 3rem;
  height: 3rem;
}
.contact-support-visit-wrap .size-wrap .item-wrap .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=ContactVisitSubmit.css.map */