.header-component-wrap {
  display: flex;
  margin: 2rem 0rem;
  width: 100%;
  position: relative;
  z-index: 20;

  .size-wrap {
    display: flex;
    align-items: center;

    .header-btn {
      @media only screen and (max-width: 63em) {
        display: none;
      }
    }

    .menu-box {
      @media only screen and (min-width: 63em) {
        display: none;
      }
      // background-color: red;
      margin-left: auto;

      .icon {
        width: 3.5rem;
        height: 3.5rem;
        color: rgb(0, 75, 255);
      }
    }

    //   .mobile-cover-menu-bg{
    //     position: fixed;
    //     top: 0%;
    //     right: 4%;
    //     width: 7rem;
    //     height: 7rem;
    //     z-index: 1000;
    //     background-color: red;
    //     -webkit-transform: scale(0);
    //             transform: scale(0);
    //     -webkit-transition: -webkit-transform 0.5s linear;
    //     transition: -webkit-transform 0.5s linear;
    //     transition: transform 0.5s linear;
    //     transition: transform 0.5s linear, -webkit-transform 0.5s linear;
    //     border-radius: 50%;

    //   @media only screen and (min-width: 57em) {
    //     display: none;
    //   }
    // }

    // .mobile-cover-menu-bg-show{
    //   -webkit-transform: scale(80);
    //   transform: scale(80);
    // }

    .list-box {
      margin: 0rem auto;
      display: flex;
      align-items: center;
      gap: 2.5rem;

      @media only screen and (max-width: 63em) {
        position: fixed;
        top: 7rem;
        right: 0;
        width: 25rem;
        min-height: 50rem;
        flex-direction: column;
        padding: 4rem 3rem 4rem 3rem;
        background-color: #ffffff;
        box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        transition: transform 0.3s ease-in-out;
        transform: translateX(100%);
      }

      .header-btn-mobile {
        margin-top: auto;
        width: 100%;

        @media only screen and (min-width: 63em) {
          display: none;
        }
      }

      .link-item {
        font-size: 1.35rem;
        cursor: pointer;
        // background-color: red;

        @media only screen and (max-width: 63em) {
          font-size: 2rem;
          font-weight: 500;
          align-self: flex-start;
        }
      }

      .link-item-active {
        color: rgba(0, 75, 255, 1);
        font-weight: 500;
      }
    }

    @media only screen and (max-width: 63em) {
      .list-box-show-mobile {
        transform: translateX(0%);
      }
    }

    .logo-box {
      .img-box {
        width: 10rem;
        height: 4rem;
        cursor: pointer;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
