.faq-card-wrap {
  width: 100%;
  padding: 1.5rem 2rem;
  border: 0.1rem solid rgba(14, 115, 246, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.faq-card-wrap .question-arrow-box {
  display: flex;
  align-items: center;
  color: var(--raven-black-color);
  cursor: pointer;
}

.faq-card-wrap .question-arrow-box .question {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
}

@media only screen and (max-width: 38em) {
  .faq-card-wrap .question-arrow-box .question {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.faq-card-wrap .question-arrow-box .icon {
  margin-left: auto;
  width: 1.6rem;
  height: 1.6rem;
  color: rgb(0, 75, 255);
}

.faq-card-wrap .answer-btn-box {
  background-color: rgba(14, 115, 246, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

.faq-card-wrap .answer-btn-box .answer {
  font-size: 1.35rem;
  color: #020202;
  line-height: 2rem;
}

@media only screen and (max-width: 38em) {
  .faq-card-wrap .answer-btn-box .answer {
    font-size: 1.5rem;
  }
}
.faq-card-wrap .answer-btn-box .text-btn-box {
  margin-top: 2rem;
}

.faq-card-wrap .answer-btn-box .text-btn-box .text {
  color: #020202;
  font-weight: 600;
  font-size: 1.55rem;
}

.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

@media only screen and (max-width: 53em) {
  .faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap {
    flex-direction: column;
  }
}
.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box {
  display: flex;
  align-items: center;
  align-self: center;
}

@media only screen and (max-width: 53em) {
  .faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box {
    flex: unset;
    align-self: flex-start;
    margin-bottom: 2rem;
    width: 100%;
  }
}
.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box > *:first-child {
  margin-right: 1.5rem;
}

.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box .img-box {
  width: 15rem;
  height: 5rem;
  cursor: pointer;
  transition: transform 0.3s;
}

@media only screen and (max-width: 38em) {
  .faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box .img-box {
    width: 17rem;
    height: 6rem;
  }
}
.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-box .img-box:hover {
  transform: translateY(-0.15rem);
}

.faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-reuse {
  margin-left: auto;
  padding: 1.2rem 3rem;
}

@media only screen and (max-width: 53em) {
  .faq-card-wrap .answer-btn-box .text-btn-box .btn-wrap .btn-reuse {
    flex: unset;
    margin-left: unset;
    width: 100%;
  }
}
.faq-card-wrap .answer-btn-box-show {
  margin-top: 3rem;
  padding: 3rem 1.5rem;
  max-height: 75rem;
  transition: max-height 0.3s ease-in-out;
}

.faq-card-wrap-active {
  border: 0.1rem solid rgb(0, 75, 255);
}

.faq-card-wrap-active .question-arrow-box .question {
  font-weight: 500;
  color: #020202;
}

/*# sourceMappingURL=FaqCard.css.map */