.plan-support-section-wrap {
  min-height: 100vh;
  background-color: rgba(239, 242, 252, 1);
  padding: 5rem 0rem;
  display: flex;

  .size-wrap {
    // background-color: red;
    // height: 100%;
    // flex: 1;
    width: 65%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;

    @media only screen and (max-width: 63em) {
      width: 90%;
    }

    @media only screen and (max-width: 50em) {
      display: flex;
      flex-direction: column;
      grid-gap: 2.5rem;
    }

    & > *:nth-child(2) {
      grid-row: 1 / -1;
      grid-column: 2 / span 1;
    }

    .card-item {
      background-color: #ffffff;
      border-radius: 0.7rem;
      padding: 3rem 2rem;
      box-shadow: 0.1rem 0.1rem 8rem 2rem rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;

      .title {
        font-size: 1.9rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      .text {
        font-size: 1.35rem;
        color: rgba(91, 104, 113, 1);
        line-height: 1.9rem;

        @media only screen and (max-width: 50em) {
          //   margin-bottom: 1.5rem;
        }
      }

      .list-box {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: auto;

        @media only screen and (max-width: 50em) {
          margin-top: 1.5rem;
        }

        .list-item {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          .list-text {
            font-size: 1.3rem;
            line-height: 1.9rem;
          }

          .circle {
            span {
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
              display: inline-block;
              background-color: rgba(0, 75, 255, 1);
            }
          }
        }
      }

      .logo-box {
        margin-bottom: 3rem;
        margin-top: 4rem;

        @media only screen and (max-width: 40em) {
          margin: 2.5rem 0rem;
        }

        .img-box {
          width: 4rem;
          height: 4rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .btn {
        align-self: flex-end;

        .text {
          color: #ffffff;

          @media only screen and (max-width: 40em) {
            // margin-bottom: unset !important;
          }
        }

        .img-box {
          display: none;
        }
      }
    }
  }
}
