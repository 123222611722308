.contact-support-visit-wrap {
  background-color: rgba(239, 242, 252, 1);
  //   min-height: 40rem;
  padding: 5rem 0rem;
  display: flex;

  .size-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 5rem;
    align-self: center;

    .item-wrap {
      display: flex;
      align-items: flex-start;
      gap: 2.5rem;

      .text-wrap{
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .title{
            font-size: 1.55rem;
            font-weight: 600;
        }

        .text{
            font-size: 1.45rem;
            width: 65%;
            color: rgba(91, 104, 113, 1);
            line-height: 2rem;
        }

        .link{
            color: rgba(0, 75, 255, 1);
            border-bottom: .1rem solid rgba(0, 75, 255, 1);
            align-self: flex-start;
            cursor: pointer;
            transition: all .3s ease-in-out;
            font-size: 1.3rem;
            font-weight: 500;

            &:hover{
                transform: scale(1.03);
            }
        }
      }

      .logo-box{
        .img-box{
            width: 3rem;
            height: 3rem;

            .img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
      }
    }
  }
}
