.get-demo-component-wrap {
  margin: 6rem 0rem;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap {
    margin: 3rem 0rem;
  }
}
.get-demo-component-wrap .size-wrap {
  display: flex;
  gap: 3.5rem;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap {
    flex-direction: column;
    gap: 1.5rem;
  }
}
.get-demo-component-wrap .size-wrap .or-box {
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
  grid-gap: 1.5rem;
  justify-items: center;
  justify-content: center;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .or-box {
    width: 100%;
  }
}
.get-demo-component-wrap .size-wrap .or-box span {
  background-color: rgb(91, 104, 113);
  display: inline-block;
  width: 0.1rem;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .or-box span {
    width: 100%;
  }
}
.get-demo-component-wrap .size-wrap .or-box p {
  text-transform: uppercase;
}
.get-demo-component-wrap .size-wrap .left-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: flex-start;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .left-wrap {
    gap: 1.5rem;
  }
}
.get-demo-component-wrap .size-wrap .left-wrap .title {
  font-weight: 600;
  font-size: 1.8rem;
}
.get-demo-component-wrap .size-wrap .left-wrap .text {
  font-size: 1.4rem;
  width: 85%;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .left-wrap .text {
    width: 100%;
  }
}
.get-demo-component-wrap .size-wrap .left-wrap .btn .text {
  width: 100%;
}
.get-demo-component-wrap .size-wrap .right-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: flex-start;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .right-wrap {
    gap: 1.5rem;
  }
}
.get-demo-component-wrap .size-wrap .right-wrap .title {
  font-weight: 600;
  font-size: 1.8rem;
}
.get-demo-component-wrap .size-wrap .right-wrap .text {
  font-size: 1.4rem;
  width: 85%;
}
@media only screen and (max-width: 63em) {
  .get-demo-component-wrap .size-wrap .right-wrap .text {
    width: 100%;
  }
}
.get-demo-component-wrap .size-wrap .right-wrap .btn {
  background-color: rgba(14, 115, 246, 0.1);
}
.get-demo-component-wrap .size-wrap .right-wrap .btn .text {
  color: rgb(14, 115, 246);
}/*# sourceMappingURL=GetDemoComponent.css.map */