.privacy-content-section-wrap {
  min-height: 100vh;
  padding: 7rem 0rem;
}
@media only screen and (max-width: 40em) {
  .privacy-content-section-wrap {
    padding: 3.5rem 0rem;
  }
}
.privacy-content-section-wrap .size-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.privacy-content-section-wrap .size-wrap .title-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy-content-section-wrap .size-wrap .title-text-wrap .title {
  font-size: 2.5rem;
  font-weight: 600;
}
.privacy-content-section-wrap .size-wrap .title-text-wrap .text {
  font-size: 1.35rem;
  color: rgb(17, 20, 45);
  text-align: left;
  width: 80%;
  line-height: 1.9rem;
}
@media only screen and (max-width: 48em) {
  .privacy-content-section-wrap .size-wrap .title-text-wrap .text {
    width: 100%;
    font-size: 1.65rem;
    text-align: justify;
    line-height: 2.5rem;
  }
}/*# sourceMappingURL=ContentSection.css.map */