.comparison-wrap {
  // padding: 0 4rem;
  width: 100%;
  @media only screen and (max-width: 48em) {
    overflow-x: scroll;
  }

  .comparison-top-title {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 3rem;

    @media only screen and (max-width: 48em) {
      text-align: center;
    }
  }

  .upgrade-wrap {
    margin-top: 3rem;
    @media only screen and (max-width: 48em) {
      .filter-yearly-monthly-box {
        display: flex;
        justify-content: center;
        place-content: center;
      }
    }
    .plan-list-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10rem;
      text-align: center;
      @media only screen and (max-width: 48em) {
        display: flex;
        padding: 0 3rem;
        gap: 2rem;
        flex-direction: column;
        // justify-self: center;
      }
      .plan-list-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 3rem;
        //   &:nth-child(1) {
        //     // display: none;
        //   }
        .plan-title {
          font-size: 1.5rem;
        }
        .plan-value {
          font-size: 2rem;
          font-weight: 500;
        }
        .comparison-btn {
          display: flex;
          justify-content: center;
          place-content: center;
        }
      }
    }

    // table styling
    .table-feature-container-to-control-mobile {
      @media only screen and (max-width: 40em) {
        width: 100%;
        padding: 0 3rem;
        overflow-x: scroll;
      }
    }
  }

  .table-feature-wrap {
    width: 100%;
    border-collapse: collapse;
    text-transform: capitalize;

    .table-row-head {
      text-align: center;
      font-weight: 500;
      display: flex;
      @media only screen and (max-width: 40em) {
        // width: 120%;
        // padding: 0 3rem;
        // overflow-x: scroll;
      }
      .table-row-title {
        padding: 3rem;
        // text-align: center;
        border: 0.1rem solid #cccc;
        width: 100%;
       
      }
    }
    .table-col-head {
      text-align: center;
      // font-weight: 500;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @media only screen and (max-width: 40em) {
        min-width: 150vh;
       
      }

      .table-col-title {
        // width: 22.7%;
        // width: 100%;
        padding: 3rem;
        text-align: center;
        border: 0.1rem solid #cccc;
        font-weight: 500;
      }
      .table-col-detail {
        color: rgba(0, 75, 255, 1);
        // color: #119c2b;
        font-weight: 400;
        // width: 100%;
        // width: max-content;
        .icon {
          font-weight: 500;
          color: #119c2b;
        }
        .times-icon {
          color: rgb(158, 57, 57);
        }
      }
    }
  }

  td {
    padding: 3rem;
    text-align: center;
    border: 0.1rem solid #cccc;
  }
}
.comparison-oval-loader {
  display: flex;
  justify-content: center;
}
