.pricing-content-section-wrap {
  //   min-height: 100vh;
  background-color: rgba(239, 242, 252, 1);
  display: flex;
  flex-direction: column;

  .size-wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 4rem 0rem;
    flex: 1;

    .card-plan-select-wrap {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      grid-gap: 2.5rem;
      min-height: 50rem;

      // @media only screen and (max-width: 63em) {
      //  gri
      //  }

      .card-plan {
        background-color: #ffffff;
        height: 100%;
        // max-height: 50rem;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        overflow: hidden;

        @media only screen and (max-width: 63em) {
          min-height: 50rem;
        }

        .btn-try {
          width: 85%;
          margin-top: auto;
          margin-bottom: 2rem;
          align-self: center;
          justify-content: center;
        }

        .sub-text {
          margin-bottom: 4rem;
          cursor: pointer;
          text-align: center;
          align-self: center;
        }

        .list-box {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin: 0rem auto;
          width: 85%;
          max-height: 30rem;
          padding-bottom: 3rem;
          overflow-y: scroll;

          -ms-overflow-style: none;
          scrollbar-width: none;
        
          &::-webkit-scrollbar {
            width: 0.5rem;
          }
        
          &::-webkit-scrollbar-track {
            background-color: #ffffff;
            -webkit-border-radius: 10px;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: rgba(240, 18, 218, 1);
          }

          .list-item {
            text-align: left;
            color: rgba(146, 146, 157, 1);
            font-size: 1.45rem;
            display: flex;
            align-items: flex-start;

            .check-icon {
              color: #119c2b;
              place-items: center;
              width: 3rem;
              // margin-right: 2rem;
              // margin-top: .3rem;
            }
          }
        }

        .top-tab {
          flex: 0 0 2.5%;
        }

        .plan-title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 3rem;
          margin-top: 3rem;
          text-align: center;
        }

        .value {
          font-size: 1.8rem;
          font-weight: 500;
          margin-bottom: 3rem;
          // margin-top: 3rem;
          opacity: 0.8;
          text-align: center;
        }
      }
    }

    .filter-yearly-monthly-box {
      //   align-self: center;
      display: flex;
      align-items: center;
      gap: 2.5rem;

      .react-select-box {
        // align-self: flex-end;
        margin-left: auto;

        .select {
          & > *:nth-child(3) {
            border-color: rgba(0, 75, 255, 1);
            justify-content: unset;

            & > *:first-child {
              padding-right: unset;

              & > *:first-child {
                // padding-right: unset;
                color: #000000;
              }
            }

            & > *:nth-child(2) {
              & > *:first-child {
                display: none;
              }

              & > *:nth-child(2) {
                svg {
                  color: #000000;
                }
              }
            }
          }
        }
      }

      .text {
        font-size: 1.35rem;
        cursor: pointer;
        font-weight: 600;
      }

      .check-input {
        display: none;

        &:checked ~ .check-label .first-child {
          visibility: hidden;
        }

        &:not(:checked) ~ .check-label .second-child {
          visibility: hidden;
        }
      }

      .check-label {
        width: 5rem;
        height: 2.3rem;
        border-radius: 2rem;
        background-color: #ffffff;
        border: 0.1rem solid rgba(0, 75, 255, 1);
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        cursor: pointer;

        span {
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 50%;
          background-color: rgba(0, 75, 255, 1);
        }
      }
    }

    // .react-select-box {
    //   align-self: flex-end;

    //   .select {
    //     & > *:nth-child(3) {
    //       border-color: rgba(0, 75, 255, 1);
    //       justify-content: unset;

    //       & > *:first-child {
    //         padding-right: unset;

    //         & > *:first-child {
    //           // padding-right: unset;
    //           color: #000000;
    //         }
    //       }

    //       & > *:nth-child(2) {
    //         & > *:first-child {
    //           display: none;
    //         }

    //         & > *:nth-child(2) {
    //           svg {
    //             color: #000000;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .oval-loader {
      display: flex;
      justify-content: center;
    }
  }
}
