.about-detail-wrap-section {
  min-height: 100vh;
  background-color: rgba(239, 242, 252, 1);
  padding: 5rem 0rem;
  display: flex;

  .size-wrap{
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 8rem;
    gap: 7rem;

    @media only screen and (max-width: 40em) {
      gap: 3.5rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    .picture-text-wrap {
        display: flex;
        min-height: 35rem;
        gap: 5rem;

        
  
        .img-content {
          flex: 0 0 50%;
          display: grid;
          place-items: center;
  
          .content {
            width: 100%;
            height: 100%;
            // background-image: url("../../assets/revex-component-img.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            
          }
        }
  
        .content-text-wrap {
          flex: 1;
          gap: 1rem;
          margin-top: 1rem;
  
          .big-text-wrap {
            .big-text {
              font-size: 2.5rem;
            }
          }
  
          .small-text-wrap {
            // width: 80%;
            
            .small-text{
              font-size: 1.45rem;
              line-height: 1.9rem;
            }
          }
        }
      }

      .picture-text-wrap {
        display: flex;
        min-height: 35rem;
        gap: 5rem;
  
        @media only screen and (max-width: 63em) {
          flex-direction: column;
          width: 100%;
          gap: 2.5rem;
          // min-height: 50rem;
        }
  
        .img-content {
          flex: 0 0 50%;
          display: grid;
          place-items: center;
  
          @media only screen and (max-width: 63em) {
            flex: unset;
            width: 100%;
            min-height: 25rem;
            order: 1;
          }
  
  
        }
  
        .content-text-wrap {
          flex: 1;
          gap: 1rem;
          margin-top: 1rem;
  
          @media only screen and (max-width: 63em) {
            flex-direction: column;
            width: 100%;
            flex: unset;
            // min-height: 25rem;
          }
  
          .big-text-wrap {
            .big-text {
              font-size: 2.5rem;
  
              @media only screen and (max-width: 63em) {
                text-align: center;
              }
            }
          }
  
          .small-text-wrap {
            width: 70%;
  
            @media only screen and (max-width: 63em) {
              width: 100%;
            }
  
            .small-text {
              font-size: 1.45rem;
  
              @media only screen and (max-width: 63em) {
                text-align: center;
                font-size: 1.65rem;
              }
  
              @media only screen and (max-width: 40em) {
               text-align: justify;
               line-height: 2.5rem;
               }
            }
          }
        }
      }
  }
}
