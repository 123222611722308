.trust-section-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.trust-section-wrap .video-bg {
  background-color: rgb(251, 251, 251);
  padding: 5rem 0rem;
  display: flex;
}
.trust-section-wrap .video-bg .size-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
}
.trust-section-wrap .video-bg .size-wrap .img-wrap .img-box {
  width: 10rem;
  height: 8rem;
}
.trust-section-wrap .video-bg .size-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=TrustSection.css.map */