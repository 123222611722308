.privacy-content-section-wrap {
  min-height: 100vh;
  padding: 7rem 0rem;

  @media only screen and (max-width: 40em) {
    padding: 3.5rem 0rem;
   }

  .size-wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .title-text-wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .title {
        font-size: 2.5rem;
        font-weight: 600;

    
      }

      .text{
        font-size: 1.35rem;
        color: rgba(17, 20, 45, 1);
        text-align: left;
        width: 80%;
        line-height: 1.9rem;

        @media only screen and (max-width: 48em){
            width: 100%;
            font-size: 1.65rem;
            text-align: justify;
            line-height: 2.5rem;

        }
      }
    }
  }
}
