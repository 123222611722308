.sub-top-items {
  margin-top: 15rem;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 48em) {
    flex-direction: column;
    gap: 3rem;
    justify-self: center;
    margin-top: 10rem;
    padding-left: 2rem;
    
  }
  .item-group {
    display: flex;
    gap: 1rem;
    // padding: 0 12rem;

    .circle {
      background-color: #004bff;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      margin-top: 0.3rem;
    }
    .item-box {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-weight: 500;
      margin-bottom: 0.4rem;
    }
  }
}
// .sub-pricing-content-wrap {
//   //   margin-top: 5rem;
// }
