.news-letter-modal-wrap {
  width: 55rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  border-radius: 1rem;
  padding: 4rem;
  position: relative;
}
@media only screen and (max-width: 48em) {
  .news-letter-modal-wrap {
    width: 90vw;
  }
}
.news-letter-modal-wrap .cancel-box {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: 0.1rem solid red;
  display: grid;
  place-items: center;
  width: 3rem;
  border-radius: 0.8rem;
  height: 3rem;
  cursor: pointer;
}
.news-letter-modal-wrap .cancel-box .icon {
  color: red;
  width: 1rem;
  height: 1rem;
}
.news-letter-modal-wrap .wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
}
.news-letter-modal-wrap .wrap .title {
  font-weight: 600;
  font-size: 2.5rem;
  color: rgb(29, 29, 24);
  text-align: center;
}
.news-letter-modal-wrap .wrap .text-news {
  font-size: 1.5rem;
  color: rgb(91, 104, 113);
  text-align: left;
}
.news-letter-modal-wrap .wrap .img-wrap {
  width: 30rem;
  height: 30rem;
}
.news-letter-modal-wrap .wrap .img-wrap .img-box {
  width: 100%;
  height: 100%;
}
.news-letter-modal-wrap .wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.news-letter-modal-wrap .wrap .form-box {
  display: flex;
  flex-direction: row;
  width: 70%;
}
@media only screen and (max-width: 48em) {
  .news-letter-modal-wrap .wrap .form-box {
    width: 90%;
    gap: 2rem;
    flex-direction: column;
  }
}
.news-letter-modal-wrap .wrap .form-box .input-group {
  flex: 0 0 70%;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 48em) {
  .news-letter-modal-wrap .wrap .form-box .input-group {
    flex: unset;
    width: 100%;
  }
}
.news-letter-modal-wrap .wrap .form-box .input-group .input-item {
  width: 100%;
  height: 100%;
  padding: 0.2rem 2rem;
  border: 0.1rem solid #cccccc;
  border-radius: 0.8rem;
  border-right: unset;
  transform: translateX(1rem);
}
@media only screen and (max-width: 48em) {
  .news-letter-modal-wrap .wrap .form-box .input-group .input-item {
    min-height: 5rem;
    border-right: 0.1rem solid #cccccc !important;
    transform: unset;
  }
}
.news-letter-modal-wrap .wrap .form-box .input-group .input-item:focus {
  border: 0.1rem solid #cccccc !important;
}
.news-letter-modal-wrap .wrap .form-box .input-group .input-item:focus:not(:valid) {
  border: 0.1rem solid red;
}
.news-letter-modal-wrap .wrap .form-box .btn-submit {
  flex: 1;
  justify-content: center;
  position: relative;
  z-index: 4;
}
@media only screen and (max-width: 48em) {
  .news-letter-modal-wrap .wrap .form-box .btn-submit {
    flex: unset;
    width: 100%;
  }
}
.news-letter-modal-wrap .wrap .form-box .btn-submit .text {
  color: #ffffff !important;
}/*# sourceMappingURL=NewsLetterModal.css.map */