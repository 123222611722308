.support-hero-section-wrap {
  //   min-height: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 15rem 0rem 5rem 0rem;

  @media only screen and (max-width: 40em) {
    width: 90%;
    margin: 15rem auto 5rem auto;
  }

  .page-title {
    font-size: 3.5vw;
    font-weight: 500;
    line-height: 6rem;
    font-family: "Lexend", sans-serif;
    text-align: center;

    @media only screen and (max-width: 40em) {
      font-size: 3rem;
      line-height: 4.5rem;
    }
  }

  .two-button-wrap {
    margin-top: 2rem;
    display: flex;
    gap: 3rem;
    align-items: center;

    .second-btn {
      background-color: rgba(14, 115, 246, 0.1);

      .text {
        color: #0e73f6;
      }
    }
  }

  .small-text {
    text-align: center;
    font-size: 1.45rem;
    font-family: "Lexend", sans-serif;
    width: 50%;
    // background-color: red;
    line-height: 1.9rem;
    color: rgba(91, 104, 113, 1);

    @media only screen and (max-width: 40em) {
      font-size: 1.65rem;
      // display: none;
      line-height: 2.5rem;
      width: 100%;
      text-align: justify;
     }
  }
}
