.comparison-wrap {
  width: 100%;
}
@media only screen and (max-width: 48em) {
  .comparison-wrap {
    overflow-x: scroll;
  }
}
.comparison-wrap .comparison-top-title {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 3rem;
}
@media only screen and (max-width: 48em) {
  .comparison-wrap .comparison-top-title {
    text-align: center;
  }
}
.comparison-wrap .upgrade-wrap {
  margin-top: 3rem;
}
@media only screen and (max-width: 48em) {
  .comparison-wrap .upgrade-wrap .filter-yearly-monthly-box {
    display: flex;
    justify-content: center;
    place-content: center;
  }
}
.comparison-wrap .upgrade-wrap .plan-list-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10rem;
  text-align: center;
}
@media only screen and (max-width: 48em) {
  .comparison-wrap .upgrade-wrap .plan-list-wrap {
    display: flex;
    padding: 0 3rem;
    gap: 2rem;
    flex-direction: column;
  }
}
.comparison-wrap .upgrade-wrap .plan-list-wrap .plan-list-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}
.comparison-wrap .upgrade-wrap .plan-list-wrap .plan-list-box .plan-title {
  font-size: 1.5rem;
}
.comparison-wrap .upgrade-wrap .plan-list-wrap .plan-list-box .plan-value {
  font-size: 2rem;
  font-weight: 500;
}
.comparison-wrap .upgrade-wrap .plan-list-wrap .plan-list-box .comparison-btn {
  display: flex;
  justify-content: center;
  place-content: center;
}
@media only screen and (max-width: 40em) {
  .comparison-wrap .upgrade-wrap .table-feature-container-to-control-mobile {
    width: 100%;
    padding: 0 3rem;
    overflow-x: scroll;
  }
}
.comparison-wrap .table-feature-wrap {
  width: 100%;
  border-collapse: collapse;
  text-transform: capitalize;
}
.comparison-wrap .table-feature-wrap .table-row-head {
  text-align: center;
  font-weight: 500;
  display: flex;
}
.comparison-wrap .table-feature-wrap .table-row-head .table-row-title {
  padding: 3rem;
  border: 0.1rem solid rgba(204, 204, 204, 0.8);
  width: 100%;
}
.comparison-wrap .table-feature-wrap .table-col-head {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
@media only screen and (max-width: 40em) {
  .comparison-wrap .table-feature-wrap .table-col-head {
    min-width: 150vh;
  }
}
.comparison-wrap .table-feature-wrap .table-col-head .table-col-title {
  padding: 3rem;
  text-align: center;
  border: 0.1rem solid rgba(204, 204, 204, 0.8);
  font-weight: 500;
}
.comparison-wrap .table-feature-wrap .table-col-head .table-col-detail {
  color: rgb(0, 75, 255);
  font-weight: 400;
}
.comparison-wrap .table-feature-wrap .table-col-head .table-col-detail .icon {
  font-weight: 500;
  color: #119c2b;
}
.comparison-wrap .table-feature-wrap .table-col-head .table-col-detail .times-icon {
  color: rgb(158, 57, 57);
}
.comparison-wrap td {
  padding: 3rem;
  text-align: center;
  border: 0.1rem solid rgba(204, 204, 204, 0.8);
}

.comparison-oval-loader {
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=comparison.css.map */