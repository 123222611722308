.pricing-hero-section-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 15rem 0rem 5rem 0rem;
}
@media only screen and (max-width: 40em) {
  .pricing-hero-section-wrap {
    width: 90%;
    margin: 15rem auto 5rem auto;
  }
}
.pricing-hero-section-wrap .page-title-box .page-title {
  font-size: 3vw;
  font-weight: 600;
  line-height: 5rem;
  font-family: "Lexend", sans-serif;
  text-align: center;
}
@media only screen and (max-width: 40em) {
  .pricing-hero-section-wrap .page-title-box .page-title {
    font-size: 2.5rem;
  }
}
.pricing-hero-section-wrap .check-text-box {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.pricing-hero-section-wrap .check-text-box .check-text {
  gap: 1rem;
  display: flex;
  align-items: center;
}
.pricing-hero-section-wrap .check-text-box .check-text .check {
  width: 2rem;
  height: 1.5rem;
  background-color: rgb(42, 46, 164);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.pricing-hero-section-wrap .check-text-box .check-text .check .icon {
  color: #ffffff;
  width: 60%;
  height: 60%;
}
.pricing-hero-section-wrap .check-text-box .check-text .text {
  color: rgb(17, 20, 45);
  font-size: 1.4rem;
  font-weight: 600;
}
@media only screen and (max-width: 40em) {
  .pricing-hero-section-wrap .check-text-box .check-text .text {
    font-size: 1.65rem;
    line-height: 2.2rem;
  }
}/*# sourceMappingURL=PricingHero.css.map */