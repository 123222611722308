.big-wrap-close-content-modal {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: transparent;

  .down-part {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: transparent;
    cursor: pointer;
  }
}

.free-trial-modal-start-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);
  width: 70vw;
  display: flex;
  height: 85vh;
  background-color: #ffffff;
  z-index: 20;

  @media only screen and (max-width: 48em) {
    flex-direction: column;
    height: 90vh;
    width: 85vw;
    overflow-y: scroll;
  }

  .left-box {
    flex: 0 0 40%;
    background-color: rgba(0, 75, 255, 1);
    display: grid;
    place-items: center;
    color: #ffffff;
    padding: 0rem 5rem;

    @media only screen and (max-width: 48em) {
      flex: unset;
      width: 100%;
      //   margin-bottom: 2rem;
      padding: 3rem 3rem;
    }

    .wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .title {
        font-weight: 600;
        font-size: 3rem;
      }

      .text {
        font-size: 1.45rem;
        margin-bottom: -5rem;

        @media only screen and (max-width: 48em) {
          margin-bottom: 0rem;
        }
      }

      .img-wrap {
        width: 100%;
        height: 30rem;
        background-image: url("../assets/free-trial-imgage.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @media only screen and (max-width: 48em) {
          display: none;
        }
      }
    }
  }

  .right-box {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 5rem;

    @media only screen and (max-width: 48em) {
      flex: unset;
      width: 100%;
      padding-left: unset;
      flex-direction: column;
      // gap: 2rem;
      padding: 3rem 0rem;
    }

    .wrap {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 48em) {
        flex: unset;
        width: 100%;
        padding: 0rem 2rem;
      }

      .img-logo {
        width: 8rem;
        height: 2.5rem;
        margin-bottom: 2rem;

        .img-box {
          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .trial-wrap {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        // align-items: center;

        @media only screen and (max-width: 48em) {
          flex-direction: column;
          gap: 1rem;
          // align-items: flex-start;
        }

        .sub-tile {
          text-transform: capitalize;
        }

        .amount {
          font-weight: 500;
          font-size: 1.2rem;
        }
      }

      .form-part {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
          padding-left: unset;
          flex-direction: column;
          gap: 2rem;
        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          margin-top: 3rem;
          max-height: 50rem;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          @media only screen and (max-width: 48em) {
            // flex: unset;
            width: 100%;
          }

          .wrap-form {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            width: 100%;
            flex: 1;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .submit-btn {
            width: 65%;
            margin-top: 1rem;
            justify-content: center;

            @media only screen and (max-width: 48em) {
              // flex: unset;
              width: 100%;
            }

            .img-box {
              display: none;
            }
          }

          .form-group {
            display: flex;
            flex-direction: column;
            width: 100%;

            .form-label {
              font-size: 1.35rem;
              font-weight: 400;
              margin-bottom: 0.3rem;
              display: inline-block;
              text-transform: capitalize;
              opacity: 0.8;
            }

            form {
              width: 100%;

              // .form-input{
              //     // width: 100%;
              // }
            }
            .form-input {
              width: 100%;
              background-color: rgba(246, 248, 249, 1);
              outline: none;
              border: none;
              padding: 1.5rem 1rem;
              border-radius: 0.8rem;
              width: 30rem;

              @media only screen and (max-width: 48em) {
                // flex: unset;
                width: 100% !important;
              }

              &:focus {
                border: 0.08rem solid rgba(0, 75, 255, 1);
              }

              &:focus:not(:valid) {
                border: 0.08rem solid red;
              }
            }

            .form-input-phone {
              padding: 1rem 0rem;
              padding-left: 5rem;
              height: 4rem !important;
            }

            .form-input-select {
              & > *:nth-child(3) {
                padding: 0.45rem 1rem;
                border-radius: 0.8rem;
                background-color: rgba(246, 248, 249, 1);
                border: none;
              }
            }

            // global input style start here -------------
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              box-shadow: 0 0 0 30px rgba(246, 248, 249, 1) inset !important;
              -webkit-box-shadow: 0 0 0 30px rgba(246, 248, 249, 1) inset !important;
            }

            .form-group-password {
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: rgba(246, 248, 249, 1);

              .form-input {
                flex: 1;
                width: 100%;
                background-color: transparent;

                &:focus {
                  border: none;
                }

                &:focus:not(:valid) {
                  border: none;
                }
              }

              .eye-box {
                cursor: pointer;
                flex: 0 0 7%;
                padding-right: 1rem;

                .icon {
                  width: 2rem;
                  height: 2rem;
                  color: #cccccc;
                }
              }
            }
          }
        }

        .assist-box {
          margin-left: 3rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;

          @media only screen and (max-width: 48em) {
            flex: unset;
            width: 100%;
            margin-left: 0rem;
          }

          .title {
            font-size: 1.8rem;
            font-weight: 600;
          }

          .small-text {
            font-size: 1.2rem;
            opacity: 0.8;
          }

          .link-text {
            font-size: 1.45rem;
            color: rgba(0, 75, 255, 1);
            border-bottom: 0.1rem solid currentColor;
            cursor: pointer;
          }
        }
      }
    }
  }
}
