.plan-support-section-wrap {
  min-height: 100vh;
  background-color: rgb(239, 242, 252);
  padding: 5rem 0rem;
  display: flex;
}
.plan-support-section-wrap .size-wrap {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2rem;
}
@media only screen and (max-width: 63em) {
  .plan-support-section-wrap .size-wrap {
    width: 90%;
  }
}
@media only screen and (max-width: 50em) {
  .plan-support-section-wrap .size-wrap {
    display: flex;
    flex-direction: column;
    grid-gap: 2.5rem;
  }
}
.plan-support-section-wrap .size-wrap > *:nth-child(2) {
  grid-row: 1/-1;
  grid-column: 2/span 1;
}
.plan-support-section-wrap .size-wrap .card-item {
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 3rem 2rem;
  box-shadow: 0.1rem 0.1rem 8rem 2rem rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
}
.plan-support-section-wrap .size-wrap .card-item .title {
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.plan-support-section-wrap .size-wrap .card-item .text {
  font-size: 1.35rem;
  color: rgb(91, 104, 113);
  line-height: 1.9rem;
}
.plan-support-section-wrap .size-wrap .card-item .list-box {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: auto;
}
@media only screen and (max-width: 50em) {
  .plan-support-section-wrap .size-wrap .card-item .list-box {
    margin-top: 1.5rem;
  }
}
.plan-support-section-wrap .size-wrap .card-item .list-box .list-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.plan-support-section-wrap .size-wrap .card-item .list-box .list-item .list-text {
  font-size: 1.3rem;
  line-height: 1.9rem;
}
.plan-support-section-wrap .size-wrap .card-item .list-box .list-item .circle span {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: inline-block;
  background-color: rgb(0, 75, 255);
}
.plan-support-section-wrap .size-wrap .card-item .logo-box {
  margin-bottom: 3rem;
  margin-top: 4rem;
}
@media only screen and (max-width: 40em) {
  .plan-support-section-wrap .size-wrap .card-item .logo-box {
    margin: 2.5rem 0rem;
  }
}
.plan-support-section-wrap .size-wrap .card-item .logo-box .img-box {
  width: 4rem;
  height: 4rem;
}
.plan-support-section-wrap .size-wrap .card-item .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.plan-support-section-wrap .size-wrap .card-item .btn {
  align-self: flex-end;
}
.plan-support-section-wrap .size-wrap .card-item .btn .text {
  color: #ffffff;
}
.plan-support-section-wrap .size-wrap .card-item .btn .img-box {
  display: none;
}/*# sourceMappingURL=PlanSection.css.map */