.about-detail-wrap-section {
  min-height: 100vh;
  background-color: rgb(239, 242, 252);
  padding: 5rem 0rem;
  display: flex;
}
.about-detail-wrap-section .size-wrap {
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  padding-bottom: 8rem;
  gap: 7rem;
}
@media only screen and (max-width: 40em) {
  .about-detail-wrap-section .size-wrap {
    gap: 3.5rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap {
  display: flex;
  min-height: 35rem;
  gap: 5rem;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .img-content {
  flex: 0 0 50%;
  display: grid;
  place-items: center;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .img-content .content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap {
  flex: 1;
  gap: 1rem;
  margin-top: 1rem;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .big-text-wrap .big-text {
  font-size: 2.5rem;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
  font-size: 1.45rem;
  line-height: 1.9rem;
}
.about-detail-wrap-section .size-wrap .picture-text-wrap {
  display: flex;
  min-height: 35rem;
  gap: 5rem;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap {
    flex-direction: column;
    width: 100%;
    gap: 2.5rem;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .img-content {
  flex: 0 0 50%;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .img-content {
    flex: unset;
    width: 100%;
    min-height: 25rem;
    order: 1;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap {
  flex: 1;
  gap: 1rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap {
    flex-direction: column;
    width: 100%;
    flex: unset;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .big-text-wrap .big-text {
  font-size: 2.5rem;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .big-text-wrap .big-text {
    text-align: center;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap {
  width: 70%;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap {
    width: 100%;
  }
}
.about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
  font-size: 1.45rem;
}
@media only screen and (max-width: 63em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
    text-align: center;
    font-size: 1.65rem;
  }
}
@media only screen and (max-width: 40em) {
  .about-detail-wrap-section .size-wrap .picture-text-wrap .content-text-wrap .small-text-wrap .small-text {
    text-align: justify;
    line-height: 2.5rem;
  }
}/*# sourceMappingURL=AboutDetails.css.map */