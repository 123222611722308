.news-letter-modal-wrap {
  width: 55rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  border-radius: 1rem;
  padding: 4rem;
  position: relative;

  @media only screen and (max-width: 48em) {
    width: 90vw;
  }

  .cancel-box {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border: 0.1rem solid red;
    // padding: .25rem .5rem;
    display: grid;
    place-items: center;
    width: 3rem;
    border-radius: 0.8rem;
    height: 3rem;
    cursor: pointer;

    .icon {
      color: red;
      width: 1rem;
      height: 1rem;
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;
    // background-color: red;

    .title {
      font-weight: 600;
      font-size: 2.5rem;
      color: rgba(29, 29, 24, 1);
      text-align: center;
    }

    .text-news {
      font-size: 1.5rem;
      color: rgba(91, 104, 113, 1);
      text-align: left;
      //   width: 65%;
    }

    .img-wrap {
      width: 30rem;
      height: 30rem;

      .img-box {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .form-box {
      display: flex;
      flex-direction: row;
      //   background-color: green;
      width: 70%;

      @media only screen and (max-width: 48em) {
        width: 90%;
        gap: 2rem;
        flex-direction: column;
      }

      .input-group {
        flex: 0 0 70%;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
        }

        .input-item {
          width: 100%;
          height: 100%;
          padding: 0.2rem 2rem;
          border: 0.1rem solid #cccccc;
          border-radius: 0.8rem;
          border-right: unset;
          transform: translateX(1rem);

          @media only screen and (max-width: 48em) {
            min-height: 5rem;
            border-right: 0.1rem solid #cccccc !important;
            transform: unset;
          }

          &:focus {
            border: 0.1rem solid #cccccc !important;
          }

          &:focus:not(:valid) {
            border: 0.1rem solid red;
          }
        }
      }

      .btn-submit {
        flex: 1;
        // width: max-content;
        justify-content: center;
        position: relative;
        z-index: 4;

        @media only screen and (max-width: 48em) {
          flex: unset;
          width: 100%;
        }

        .text {
          color: #ffffff !important;
        }
      }
    }
  }
}
