.upgrade-plan-wrap {
  background-color: rgba(239, 242, 252, 1);
  min-width: 70rem;
  height: 62.5rem;
  border-radius: 1rem;
  
  @media only screen and (max-width: 48em) {
    height: 100vh;
    // width: 100vh;
    overflow-x: hidden;
    // overflow-y: scroll;
  }
  .upgrade-plan-container {
    align-items: center;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 48em) {
      padding: 0 4rem;
    }
  }

  .upgrade-title {
    font-weight: 500;
    margin-top: 3rem;
    // margin-bottom: unset;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .cancel-icon {
    position: absolute;
    right: 2rem;
    top: 1rem;
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
    background-color: rgba(255, 239, 235, 1);
  }
  .current-plan-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    .upgrade-select {
      width: 18rem;
      font-size: 1.3rem;
      z-index: 500;
    }
    // .upgrade-active-select {
    //   // border: 0.1rem solid rgba(0, 75, 255, 1);
    //   // border-radius: 1rem ;
    // }
  }
  .plan-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .upgrade-amount {
      font-weight: 400;
      margin-bottom: 1rem;
    }

    .select-date-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background: rgba(236, 237, 237, 1);
      font-weight: 400;
      border-radius: 0.5rem;
      width: 25rem;
      justify-self: space-between;
      cursor: pointer;

      .select-date {
        padding: 1rem 1rem;
      }

      .active-select {
        border-radius: 0.5rem;
        background: rgb(0, 75, 255);
        color: #fff;
      }
    }
  }
  .current-plan-container {
    margin-top: 3rem;
    .plan-details-wrap {
      // margin-top: 3rem;
      .plan-details-container {
        height: 15rem;
        overflow-y: auto;
        overflow-x: hidden;
        // overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          background-color: #ffffff;
          -webkit-border-radius: 10px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background: rgb(0, 75, 255);
        }
      }

      .plan-title {
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        align-items: center;
        width: 30rem;
      }
      .plan-details-box {
        display: flex;
        align-items: center;
        // margin-left: 10rem;
        gap: 1rem;
        text-transform: capitalize;
        margin-top: 1rem;
        width: 30rem;

        .check-box {
          margin-top: 0.5em;
        }
      }
    }
  }
  .contact-info-wrap {
    display: flex;
    flex-direction: column;
    // gap: 2rem;
    // margin-top: 3rem;

    .contact-label {
      font-size: 1.35rem;
      font-weight: 400;
      margin-bottom: 0.3rem;
      display: inline-block;
      text-transform: capitalize;
      opacity: 0.8;
    }

    .contact-input {
      width: 100%;
      background-color: #ffffff;
      outline: none;
      border: none;
      padding: 1.5rem 1rem;
      border-radius: 0.8rem;

      &:focus {
        border: 0.08rem solid rgba(0, 75, 255, 1);
      }

      &:focus:not(:valid) {
        border: 0.08rem solid red;
      }
    }
    .contact-input-active {
      border: 0.1rem solid rgba(0, 75, 255, 1);
      // background-color: red;
    }
  }
  .upgrade-btn {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: unset;
  }
}
