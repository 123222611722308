.big-wrap-close-content-modal {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
}
.big-wrap-close-content-modal .down-part {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: transparent;
  cursor: pointer;
}

.free-trial-modal-start-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);
  width: 70vw;
  display: flex;
  height: 85vh;
  background-color: #ffffff;
  z-index: 20;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap {
    flex-direction: column;
    height: 90vh;
    width: 85vw;
    overflow-y: scroll;
  }
}
.free-trial-modal-start-wrap .left-box {
  flex: 0 0 40%;
  background-color: rgb(0, 75, 255);
  display: grid;
  place-items: center;
  color: #ffffff;
  padding: 0rem 5rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .left-box {
    flex: unset;
    width: 100%;
    padding: 3rem 3rem;
  }
}
.free-trial-modal-start-wrap .left-box .wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.free-trial-modal-start-wrap .left-box .wrap .title {
  font-weight: 600;
  font-size: 3rem;
}
.free-trial-modal-start-wrap .left-box .wrap .text {
  font-size: 1.45rem;
  margin-bottom: -5rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .left-box .wrap .text {
    margin-bottom: 0rem;
  }
}
.free-trial-modal-start-wrap .left-box .wrap .img-wrap {
  width: 100%;
  height: 30rem;
  background-image: url("../assets/free-trial-imgage.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .left-box .wrap .img-wrap {
    display: none;
  }
}
.free-trial-modal-start-wrap .right-box {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 5rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box {
    flex: unset;
    width: 100%;
    padding-left: unset;
    flex-direction: column;
    padding: 3rem 0rem;
  }
}
.free-trial-modal-start-wrap .right-box .wrap {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap {
    flex: unset;
    width: 100%;
    padding: 0rem 2rem;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .img-logo {
  width: 8rem;
  height: 2.5rem;
  margin-bottom: 2rem;
}
.free-trial-modal-start-wrap .right-box .wrap .img-logo .img-box {
  width: 100%;
  height: 100%;
}
.free-trial-modal-start-wrap .right-box .wrap .img-logo .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.free-trial-modal-start-wrap .right-box .wrap .trial-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .trial-wrap {
    flex-direction: column;
    gap: 1rem;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .trial-wrap .sub-tile {
  text-transform: capitalize;
}
.free-trial-modal-start-wrap .right-box .wrap .trial-wrap .amount {
  font-weight: 500;
  font-size: 1.2rem;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .form-part {
    flex: unset;
    width: 100%;
    padding-left: unset;
    flex-direction: column;
    gap: 2rem;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 3rem;
  max-height: 50rem;
  overflow-y: scroll;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .form-part .form {
    width: 100%;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .wrap-form {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .wrap-form::-webkit-scrollbar {
  display: none;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .submit-btn {
  width: 65%;
  margin-top: 1rem;
  justify-content: center;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .form-part .form .submit-btn {
    width: 100%;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .submit-btn .img-box {
  display: none;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-label {
  font-size: 1.35rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  display: inline-block;
  text-transform: capitalize;
  opacity: 0.8;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group form {
  width: 100%;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input {
  width: 100%;
  background-color: rgb(246, 248, 249);
  outline: none;
  border: none;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
  width: 30rem;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input {
    width: 100% !important;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input:focus {
  border: 0.08rem solid rgb(0, 75, 255);
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input:focus:not(:valid) {
  border: 0.08rem solid red;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input-phone {
  padding: 1rem 0rem;
  padding-left: 5rem;
  height: 4rem !important;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-input-select > *:nth-child(3) {
  padding: 0.45rem 1rem;
  border-radius: 0.8rem;
  background-color: rgb(246, 248, 249);
  border: none;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group input:-webkit-autofill,
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group input:-webkit-autofill:hover,
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group input:-webkit-autofill:focus,
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgb(246, 248, 249) inset !important;
  -webkit-box-shadow: 0 0 0 30px rgb(246, 248, 249) inset !important;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(246, 248, 249);
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password .form-input {
  flex: 1;
  width: 100%;
  background-color: transparent;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password .form-input:focus {
  border: none;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password .form-input:focus:not(:valid) {
  border: none;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password .eye-box {
  cursor: pointer;
  flex: 0 0 7%;
  padding-right: 1rem;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .form .form-group .form-group-password .eye-box .icon {
  width: 2rem;
  height: 2rem;
  color: #cccccc;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .assist-box {
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}
@media only screen and (max-width: 48em) {
  .free-trial-modal-start-wrap .right-box .wrap .form-part .assist-box {
    flex: unset;
    width: 100%;
    margin-left: 0rem;
  }
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .assist-box .title {
  font-size: 1.8rem;
  font-weight: 600;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .assist-box .small-text {
  font-size: 1.2rem;
  opacity: 0.8;
}
.free-trial-modal-start-wrap .right-box .wrap .form-part .assist-box .link-text {
  font-size: 1.45rem;
  color: rgb(0, 75, 255);
  border-bottom: 0.1rem solid currentColor;
  cursor: pointer;
}/*# sourceMappingURL=FreeTrialModal.css.map */