.general-overview-layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}
@media only screen and (max-width: 63em) {
  .general-overview-layout .header-wrap {
    position: relative;
    z-index: 20;
  }
}
.general-overview-layout .main-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 63em) {
  .general-overview-layout .main-wrap {
    position: relative;
    z-index: 10;
  }
}
.general-overview-layout .footer-wrap {
  margin: 5rem 0rem;
}
@media only screen and (max-width: 40em) {
  .general-overview-layout .footer-wrap {
    margin-top: 2rem;
  }
}
.general-overview-layout .footer-wrap .size-wrap .logo-text-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
}
@media only screen and (max-width: 63em) {
  .general-overview-layout .footer-wrap .size-wrap .logo-text-wrap {
    flex-direction: column;
    gap: 2rem;
  }
}
.general-overview-layout .footer-wrap .size-wrap .logo-text-wrap .img-box {
  width: 8rem;
  height: 3.5rem;
  cursor: pointer;
}
.general-overview-layout .footer-wrap .size-wrap .logo-text-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.general-overview-layout .footer-wrap .size-wrap .logo-text-wrap .text {
  color: rgb(69, 90, 100);
  font-size: 1.3rem;
}
.general-overview-layout .footer-wrap .size-wrap .list-link-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
}
@media only screen and (max-width: 63em) {
  .general-overview-layout .footer-wrap .size-wrap .list-link-box {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
}
.general-overview-layout .footer-wrap .size-wrap .list-link-box .list-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.general-overview-layout .footer-wrap .size-wrap .list-link-box .list-box .list-item {
  font-size: 1.3rem;
  cursor: pointer;
}
.general-overview-layout .footer-wrap .size-wrap .list-link-box .list-box .list-item-active {
  font-weight: 700;
  cursor: unset;
}
.general-overview-layout .footer-wrap .size-wrap .list-link-box .list-box .link-item-active {
  color: rgb(0, 75, 255);
  font-weight: 500;
}/*# sourceMappingURL=OverviewLayout.css.map */