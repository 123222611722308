.request-demo-section-wrap {
  display: flex;
  margin-top: 15rem;
  overflow: unset;
  margin-bottom: 4rem;


  @media only screen and (max-width: 63em) {
   margin-top: 10rem;
  }

  .size-wrap {
    display: flex;
    position: relative;
    min-height: 100vh;
    // overflow: scroll;


    @media only screen and (max-width: 63em) {
     flex-direction: column;
    }

    .left-wrap {
      flex: 0 0 45%;
      // background-color: red;
      display: flex;
      flex-direction: column;
      position: -webkit-sticky;
      height: max-content;
      position: sticky;
      top: 0;
      left: 0;

      @media only screen and (max-width: 63em) {
        flex: unset;
        width: 100%;
        margin-bottom: 4rem;
       }

      .title-one {
        font-size: 2.5rem;
        font-weight: 450;
        margin-bottom: 1.5rem;
      }

      .title-two {
        color: rgba(91, 104, 113, 1);
        font-size: 2.5rem;
        // font-weight: 450;
      }

      .request-badge {
        margin-bottom: 3rem;
        border-radius: 2rem;
        padding: 1rem;
        background-color: rgba(0, 75, 255, 0.2);
        align-self: flex-start;

        span {
          color: rgba(0, 75, 255, 1);
          font-weight: 500;
        }
      }

      .list-box {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 85%;
        margin-bottom: auto;

        .list-item {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          .list-text {
            font-size: 1.3rem;
            line-height: 1.9rem;
          }

          .circle {
            span {
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
              display: inline-block;
              background-color: rgba(0, 75, 255, 1);
            }
          }
        }
      }
    }

    .right-wrap {
      flex: 1;
      display: flex;
    //   justify-content: center;
    
    @media only screen and (max-width: 63em) {
      flex: unset;
      width: 100%;
      margin-bottom: 4rem;
     }

      .form-wrap {
        width: 70%;
        height: 100%;
        background-color: rgba(239, 242, 252, 1);
        display: flex;
        flex-direction: column;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        overflow: hidden;

        @media only screen and (max-width: 63em) {
          flex: unset;
          width: 100%;
         }

        .top-tab {
          flex: 0 0 1.5rem;
          background-color: rgba(0, 75, 255, 1);
        }

        .content-wrap {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding: 2rem 3rem;

          .title {
            font-size: 1.65rem;
            font-weight: 500;
          }

          .sub-title {
            font-size: 1.35rem;
            // font-weight: 500;
            cursor: pointer;
            align-self: flex-start;
            border-bottom: .1rem solid currentColor;
          }

          .form{
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .form-group{
                display: flex;
                flex-direction: column;

                .form-label{
                    font-size: 1.35rem;
                    font-weight: 400;
                    margin-bottom: .3rem;
                    display: inline-block;
                    text-transform: capitalize;
                    opacity: .8;
                }

                .form-input{
                    width: 100%;
                    background-color: #ffffff;
                    outline: none;
                    border: none;
                    padding: 1.5rem  1rem;
                    border-radius: .8rem;

                    &:focus{
                        border: .08rem solid rgba(0, 75, 255, 1);
                    }

                    &:focus:not(:valid){
                        border: .08rem solid red; 
                    }
                }

                .form-input-select{
                    &>*:nth-child(3){
                        padding: .45rem 1rem;
                        border-radius: .8rem;
                        background-color: #ffffff;
                        border: none;
                    }
                }
            }

            .form-submit{
                align-self: flex-start;
                border-radius: .8rem;
                border: none;
                outline: none;
                padding: 1.2rem  2.5rem;
                background-color: rgba(0, 75, 255, 1);
                color: #ffffff;
                margin-top: 2rem;
                cursor: pointer;
                margin-bottom: 1rem;
                transition: all .3s ease-in-out;

                &:hover{
                    transform:  translateY(-.1rem);
                }
            }
          }
        }
      }
    }
  }
}
