.pricing-hero-section-wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 15rem 0rem 5rem 0rem;

  @media only screen and (max-width: 40em) {
    width: 90%;
    margin: 15rem auto 5rem auto;
   }

  .page-title-box {
    .page-title {
      font-size: 3vw;
      font-weight: 600;
      line-height: 5rem;
      font-family: "Lexend", sans-serif;
      text-align: center;

      @media only screen and (max-width: 40em) {
        font-size: 2.5rem;
       }
    }
  }

  .check-text-box {
    display: flex;
    align-items: center;
    gap: 3rem;

    .check-text {
      gap: 1rem;
      display: flex;
      align-items: center;

      .check {
        width: 2rem;
        height: 1.5rem;
        background-color: rgba(42, 46, 164, 1);
        border-radius: 50%;
        // padding: 0.7rem;
        display: grid;
        place-items: center;

        .icon {
          color: #ffffff;
          width: 60%;
          height: 60%;
        }
      }

      .text {
        color: rgba(17, 20, 45, 1);
        font-size: 1.4rem;
        font-weight: 600;

        @media only screen and (max-width: 40em) {
          font-size: 1.65rem;
          line-height: 2.2rem;
         }
      }
    }
  }
}
