.home-hero-section {
  display: flex;
  height: 100vh;
}
@media only screen and (max-width: 63em) {
  .home-hero-section {
    flex-direction: column;
    min-height: 100vh;
    height: unset;
    min-height: unset;
    margin-bottom: 15rem;
  }
}
@media only screen and (max-width: 40em) {
  .home-hero-section {
    margin-bottom: 1rem;
  }
}
.home-hero-section .left-wrap {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 63em) {
  .home-hero-section .left-wrap {
    flex: unset;
    width: 100%;
    margin-top: 15rem;
  }
}
.home-hero-section .left-wrap .content-text-wrap .big-text-wrap .big-text {
  font-size: 3.5vw;
  line-height: 5rem;
  font-family: "Lexend", sans-serif;
}
@media only screen and (max-width: 63em) {
  .home-hero-section .left-wrap .content-text-wrap .big-text-wrap .big-text {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}
@media only screen and (max-width: 40em) {
  .home-hero-section .left-wrap .content-text-wrap .big-text-wrap .big-text {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
.home-hero-section .left-wrap .content-text-wrap .big-text-wrap .big-text span {
  color: #0e73f6;
}
.home-hero-section .left-wrap .content-text-wrap .small-text-wrap {
  width: 75%;
}
@media only screen and (max-width: 63em) {
  .home-hero-section .left-wrap .content-text-wrap .small-text-wrap {
    width: 100%;
    font-size: 1.65rem;
    line-height: 1.55rem;
  }
}
.home-hero-section .left-wrap .content-text-wrap .small-text-wrap .small-text {
  font-family: "Lexend", sans-serif;
  line-height: 2rem;
}
@media only screen and (max-width: 40em) {
  .home-hero-section .left-wrap .content-text-wrap .small-text-wrap .small-text {
    font-size: 1.65rem;
    line-height: 2.5rem;
  }
}
.home-hero-section .left-wrap .content-text-wrap .two-button-wrap {
  margin-top: 3rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}
.home-hero-section .left-wrap .content-text-wrap .two-button-wrap .second-btn {
  background-color: rgba(14, 115, 246, 0.1);
}
.home-hero-section .left-wrap .content-text-wrap .two-button-wrap .second-btn .text {
  color: #0e73f6;
}
.home-hero-section .right-wrap {
  flex: 1;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 63em) {
  .home-hero-section .right-wrap {
    flex: unset;
    margin-top: 6rem;
  }
}
.home-hero-section .right-wrap .img-content {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/home-hero-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 63em) {
  .home-hero-section .right-wrap .img-content {
    width: 100%;
    height: 35rem;
  }
}
@media only screen and (max-width: 63em) and (max-width: 40em) {
  .home-hero-section .right-wrap .img-content {
    height: 25rem;
  }
}/*# sourceMappingURL=HeroSection.css.map */