.button-wrap {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  background-color: rgb(0, 75, 255);
  padding: 1.1rem 1.5rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
@media only screen and (max-width: 63em) {
  .button-wrap {
    padding: 2rem;
    justify-content: center;
  }
}
.button-wrap:hover .img-box {
  transform: translateX(0.35rem);
}
.button-wrap .text {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
}
@media only screen and (max-width: 63em) {
  .button-wrap .text {
    font-size: 1.45rem;
  }
}
.button-wrap .img-box {
  width: 2.3rem;
  height: 0.8rem;
  display: grid;
  place-items: center;
  transition: all 0.3s ease-in-out;
}
.button-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=ReuseButton.css.map */