.header-component-wrap {
  display: flex;
  margin: 2rem 0rem;
  width: 100%;
  position: relative;
  z-index: 20;
}
.header-component-wrap .size-wrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 63em) {
  .header-component-wrap .size-wrap .header-btn {
    display: none;
  }
}
.header-component-wrap .size-wrap .menu-box {
  margin-left: auto;
}
@media only screen and (min-width: 63em) {
  .header-component-wrap .size-wrap .menu-box {
    display: none;
  }
}
.header-component-wrap .size-wrap .menu-box .icon {
  width: 3.5rem;
  height: 3.5rem;
  color: rgb(0, 75, 255);
}
.header-component-wrap .size-wrap .list-box {
  margin: 0rem auto;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
@media only screen and (max-width: 63em) {
  .header-component-wrap .size-wrap .list-box {
    position: fixed;
    top: 7rem;
    right: 0;
    width: 25rem;
    min-height: 50rem;
    flex-direction: column;
    padding: 4rem 3rem 4rem 3rem;
    background-color: #ffffff;
    box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.1);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
}
.header-component-wrap .size-wrap .list-box .header-btn-mobile {
  margin-top: auto;
  width: 100%;
}
@media only screen and (min-width: 63em) {
  .header-component-wrap .size-wrap .list-box .header-btn-mobile {
    display: none;
  }
}
.header-component-wrap .size-wrap .list-box .link-item {
  font-size: 1.35rem;
  cursor: pointer;
}
@media only screen and (max-width: 63em) {
  .header-component-wrap .size-wrap .list-box .link-item {
    font-size: 2rem;
    font-weight: 500;
    align-self: flex-start;
  }
}
.header-component-wrap .size-wrap .list-box .link-item-active {
  color: rgb(0, 75, 255);
  font-weight: 500;
}
@media only screen and (max-width: 63em) {
  .header-component-wrap .size-wrap .list-box-show-mobile {
    transform: translateX(0%);
  }
}
.header-component-wrap .size-wrap .logo-box .img-box {
  width: 10rem;
  height: 4rem;
  cursor: pointer;
}
.header-component-wrap .size-wrap .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=HeaderComponent.css.map */