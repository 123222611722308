.customer-section-wrap {
  display: flex;
  flex-direction: column;
  margin: 5rem 0rem;
}
.customer-section-wrap .size-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.customer-section-wrap .size-wrap .stories-box {
  display: flex;
  transition: all 0.3s;
}
@media only screen and (max-width: 63em) {
  .customer-section-wrap .size-wrap .stories-box {
    flex-direction: column;
    gap: 4rem;
  }
}
.customer-section-wrap .size-wrap .stories-box .left-wrap {
  flex: 0 0 55%;
  padding-left: 3rem;
}
@media only screen and (max-width: 63em) {
  .customer-section-wrap .size-wrap .stories-box .left-wrap {
    flex: unset;
    width: 100%;
    padding-left: unset;
  }
}
.customer-section-wrap .size-wrap .stories-box .left-wrap .dot-box {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
}
.customer-section-wrap .size-wrap .stories-box .left-wrap .dot-box .dot {
  width: 4.5rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  background-color: rgba(43, 140, 247, 0.2);
}
.customer-section-wrap .size-wrap .stories-box .left-wrap .dot-box .dot-active {
  background-color: rgb(0, 75, 255);
}
.customer-section-wrap .size-wrap .stories-box .left-wrap .text {
  font-size: 1.55rem;
  line-height: 2.2rem;
  width: 70%;
}
@media only screen and (max-width: 63em) {
  .customer-section-wrap .size-wrap .stories-box .left-wrap .text {
    width: 100%;
  }
}
.customer-section-wrap .size-wrap .stories-box .left-wrap .name-des {
  font-weight: 600;
  font-size: 1.55rem;
  color: rgb(91, 104, 113);
  margin-top: 2rem;
}
.customer-section-wrap .size-wrap .stories-box .right-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25rem;
  width: 100%;
}
@media only screen and (max-width: 63em) {
  .customer-section-wrap .size-wrap .stories-box .right-wrap {
    flex: unset;
  }
}
.customer-section-wrap .size-wrap .stories-box .right-wrap .img-content {
  width: 100%;
  height: 100%;
  background-position: left center;
  background-size: contain;
  border-radius: 1rem;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 63em) {
  .customer-section-wrap .size-wrap .stories-box .right-wrap .img-content {
    width: 100%;
  }
}
.customer-section-wrap .size-wrap .section-title {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.customer-section-wrap .size-wrap .section-title .img-box {
  width: 3rem;
  height: 3rem;
}
.customer-section-wrap .size-wrap .section-title .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.customer-section-wrap .size-wrap .section-title .text {
  font-weight: 500;
  font-size: 2.5rem;
  color: rgb(252, 165, 73);
  font-family: "Lexend", sans-serif;
}/*# sourceMappingURL=CustomersSection.css.map */