.button-wrap {
  display: flex;
  align-items: center;
  gap: .6rem;
  background-color: rgba(0, 75, 255, 1);
  padding: 1.1rem 1.5rem;
  border-radius: 0.6rem;
  cursor: pointer;

  @media only screen and (max-width: 63em) {
    padding: 2rem ;
    justify-content: center;
  }

  &:hover .img-box{
    transform: translateX(.35rem);
  }

  .text {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 400;
    
    @media only screen and (max-width: 63em) {
     font-size: 1.45rem;
    }
  }

  .img-box {
    width: 2.3rem;
    height: 0.8rem;
    display: grid;
    place-items: center;
    transition: all .3s ease-in-out;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
