.pricing-content-section-wrap {
  background-color: rgb(239, 242, 252);
  display: flex;
  flex-direction: column;
}
.pricing-content-section-wrap .size-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 4rem 0rem;
  flex: 1;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2.5rem;
  min-height: 50rem;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
}
@media only screen and (max-width: 63em) {
  .pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan {
    min-height: 50rem;
  }
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .btn-try {
  width: 85%;
  margin-top: auto;
  margin-bottom: 2rem;
  align-self: center;
  justify-content: center;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .sub-text {
  margin-bottom: 4rem;
  cursor: pointer;
  text-align: center;
  align-self: center;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0rem auto;
  width: 85%;
  max-height: 30rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box::-webkit-scrollbar {
  width: 0.5rem;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(240, 18, 218);
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box .list-item {
  text-align: left;
  color: rgb(146, 146, 157);
  font-size: 1.45rem;
  display: flex;
  align-items: flex-start;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .list-box .list-item .check-icon {
  color: #119c2b;
  place-items: center;
  width: 3rem;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .top-tab {
  flex: 0 0 2.5%;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .plan-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;
}
.pricing-content-section-wrap .size-wrap .card-plan-select-wrap .card-plan .value {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 3rem;
  opacity: 0.8;
  text-align: center;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box {
  margin-left: auto;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box .select > *:nth-child(3) {
  border-color: rgb(0, 75, 255);
  justify-content: unset;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box .select > *:nth-child(3) > *:first-child {
  padding-right: unset;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box .select > *:nth-child(3) > *:first-child > *:first-child {
  color: #000000;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box .select > *:nth-child(3) > *:nth-child(2) > *:first-child {
  display: none;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .react-select-box .select > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  color: #000000;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .text {
  font-size: 1.35rem;
  cursor: pointer;
  font-weight: 600;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .check-input {
  display: none;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .check-input:checked ~ .check-label .first-child {
  visibility: hidden;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .check-input:not(:checked) ~ .check-label .second-child {
  visibility: hidden;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .check-label {
  width: 5rem;
  height: 2.3rem;
  border-radius: 2rem;
  background-color: #ffffff;
  border: 0.1rem solid rgb(0, 75, 255);
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.pricing-content-section-wrap .size-wrap .filter-yearly-monthly-box .check-label span {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: rgb(0, 75, 255);
}
.pricing-content-section-wrap .size-wrap .oval-loader {
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=PricingContent.css.map */