.home-hero-section {
  display: flex;
  height: 100vh;

  @media only screen and (max-width: 63em) {
    flex-direction: column;
    min-height: 100vh;
    height: unset;
    min-height: unset;
    margin-bottom: 15rem;
  }

  @media only screen and (max-width: 40em) {
   margin-bottom: 1rem;
  }

  .left-wrap {
    flex: 0 0 50%;
    // background-color: red;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 63em) {
      flex: unset;
      width: 100%;
      margin-top: 15rem;
    }

    .content-text-wrap {
      .big-text-wrap {
        .big-text {
          font-size: 3.5vw;
          line-height: 5rem;
          font-family: "Lexend", sans-serif;

          @media only screen and (max-width: 63em) {
            font-size: 4rem;
            line-height: 4.5rem;
          }

          @media only screen and (max-width: 40em) {
            font-size: 3rem;
            line-height: 3.5rem;
          }

          span {
            color: #0e73f6;
            // color: #FCA549;
          }
        }
      }

      .small-text-wrap {
        width: 75%;

        @media only screen and (max-width: 63em) {
          width: 100%;
          font-size: 1.65rem;
          line-height: 1.55rem;
        }

        .small-text {
          font-family: "Lexend", sans-serif;
          line-height: 2rem;

          @media only screen and (max-width: 40em) {
            font-size: 1.65rem;
            line-height: 2.5rem;
          }
        }
      }

      .two-button-wrap {
        margin-top: 3rem;
        display: flex;
        gap: 3rem;
        align-items: center;

        .second-btn {
          background-color: rgba(14, 115, 246, 0.1);

          .text {
            color: #0e73f6;
          }
        }
      }
    }
  }

  .right-wrap {
    flex: 1;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 63em) {
      flex: unset;
      margin-top: 6rem;
      // min-height: 25rem;
    }

    .img-content {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/home-hero-img.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @media only screen and (max-width: 63em) {
       width: 100%;
       height: 35rem;

       @media only screen and (max-width: 40em) {
        height: 25rem;
      }
      }
    }
  }
}
