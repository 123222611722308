.request-demo-section-wrap {
  display: flex;
  margin-top: 15rem;
  overflow: unset;
  margin-bottom: 4rem;
}
@media only screen and (max-width: 63em) {
  .request-demo-section-wrap {
    margin-top: 10rem;
  }
}
.request-demo-section-wrap .size-wrap {
  display: flex;
  position: relative;
  min-height: 100vh;
}
@media only screen and (max-width: 63em) {
  .request-demo-section-wrap .size-wrap {
    flex-direction: column;
  }
}
.request-demo-section-wrap .size-wrap .left-wrap {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  height: -moz-max-content;
  height: max-content;
  position: sticky;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 63em) {
  .request-demo-section-wrap .size-wrap .left-wrap {
    flex: unset;
    width: 100%;
    margin-bottom: 4rem;
  }
}
.request-demo-section-wrap .size-wrap .left-wrap .title-one {
  font-size: 2.5rem;
  font-weight: 450;
  margin-bottom: 1.5rem;
}
.request-demo-section-wrap .size-wrap .left-wrap .title-two {
  color: rgb(91, 104, 113);
  font-size: 2.5rem;
}
.request-demo-section-wrap .size-wrap .left-wrap .request-badge {
  margin-bottom: 3rem;
  border-radius: 2rem;
  padding: 1rem;
  background-color: rgba(0, 75, 255, 0.2);
  align-self: flex-start;
}
.request-demo-section-wrap .size-wrap .left-wrap .request-badge span {
  color: rgb(0, 75, 255);
  font-weight: 500;
}
.request-demo-section-wrap .size-wrap .left-wrap .list-box {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 85%;
  margin-bottom: auto;
}
.request-demo-section-wrap .size-wrap .left-wrap .list-box .list-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.request-demo-section-wrap .size-wrap .left-wrap .list-box .list-item .list-text {
  font-size: 1.3rem;
  line-height: 1.9rem;
}
.request-demo-section-wrap .size-wrap .left-wrap .list-box .list-item .circle span {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: inline-block;
  background-color: rgb(0, 75, 255);
}
.request-demo-section-wrap .size-wrap .right-wrap {
  flex: 1;
  display: flex;
}
@media only screen and (max-width: 63em) {
  .request-demo-section-wrap .size-wrap .right-wrap {
    flex: unset;
    width: 100%;
    margin-bottom: 4rem;
  }
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap {
  width: 70%;
  height: 100%;
  background-color: rgb(239, 242, 252);
  display: flex;
  flex-direction: column;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}
@media only screen and (max-width: 63em) {
  .request-demo-section-wrap .size-wrap .right-wrap .form-wrap {
    flex: unset;
    width: 100%;
  }
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .top-tab {
  flex: 0 0 1.5rem;
  background-color: rgb(0, 75, 255);
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .title {
  font-size: 1.65rem;
  font-weight: 500;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .sub-title {
  font-size: 1.35rem;
  cursor: pointer;
  align-self: flex-start;
  border-bottom: 0.1rem solid currentColor;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group .form-label {
  font-size: 1.35rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  display: inline-block;
  text-transform: capitalize;
  opacity: 0.8;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group .form-input {
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group .form-input:focus {
  border: 0.08rem solid rgb(0, 75, 255);
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group .form-input:focus:not(:valid) {
  border: 0.08rem solid red;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-group .form-input-select > *:nth-child(3) {
  padding: 0.45rem 1rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  border: none;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-submit {
  align-self: flex-start;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  padding: 1.2rem 2.5rem;
  background-color: rgb(0, 75, 255);
  color: #ffffff;
  margin-top: 2rem;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}
.request-demo-section-wrap .size-wrap .right-wrap .form-wrap .content-wrap .form .form-submit:hover {
  transform: translateY(-0.1rem);
}/*# sourceMappingURL=RequestDemo.css.map */