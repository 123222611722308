.customer-section-wrap {
  display: flex;
  flex-direction: column;
  margin: 5rem 0rem;

  .size-wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .stories-box {
      display: flex;
      transition: all 0.3s;

      @media only screen and (max-width: 63em) {
        flex-direction: column;
        gap: 4rem;
      }

      .left-wrap {
        flex: 0 0 55%;
        padding-left: 3rem;

        @media only screen and (max-width: 63em) {
          flex: unset;
          width: 100%;
          padding-left: unset;
        }

        .dot-box {
          display: flex;
          align-items: center;
          gap: 2rem;
          margin-top: 3rem;

          .dot {
            width: 4.5rem;
            height: 1.2rem;
            border-radius: 0.6rem;
            background-color: rgba(43, 140, 247, 0.2);
          }

          .dot-active {
            background-color: rgba(0, 75, 255, 1);
          }
        }

        .text {
          font-size: 1.55rem;
          line-height: 2.2rem;
          width: 70%;

          @media only screen and (max-width: 63em) {
            width: 100%;
          }
        }

        .name-des {
          font-weight: 600;
          font-size: 1.55rem;
          color: rgba(91, 104, 113, 1);
          margin-top: 2rem;
        }
      }

      .right-wrap {
        // background-color: red;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 25rem;
        width: 100%;

        @media only screen and (max-width: 63em) {
          flex: unset;
        }

        .img-content {
          width: 100%;
          height: 100%;
          background-position: left center;
          background-size: contain;
          border-radius: 1rem;
          background-repeat: no-repeat;

          @media only screen and (max-width: 63em) {
           width: 100%;
          }
        }
      }
    }

    .section-title {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .img-box {
        width: 3rem;
        height: 3rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        font-weight: 500;
        font-size: 2.5rem;
        color: rgba(252, 165, 73, 1);
        font-family: "Lexend", sans-serif;
      }
    }
  }
}
