.get-demo-component-wrap {
  margin: 6rem 0rem;

  @media only screen and (max-width: 63em) {
    margin: 3rem 0rem;
  }

  .size-wrap {
    display: flex;
    // min-height: 35rem;
    gap: 3.5rem;

    @media only screen and (max-width: 63em) {
      flex-direction: column;
      gap: 1.5rem;
    }

    .or-box {
      display: grid;
      grid-template-rows: 1fr min-content 1fr;
      grid-gap: 1.5rem;
      //   align-items: center;
      justify-items: center;
      justify-content: center;

      @media only screen and (max-width: 63em) {
        width: 100%;
      }

      span {
        background-color: rgba(91, 104, 113, 1);
        display: inline-block;
        width: 0.1rem;

        @media only screen and (max-width: 63em) {
          width: 100%;
        }
      }

      p {
        text-transform: uppercase;
      }
    }

    .left-wrap {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      align-items: flex-start;

      @media only screen and (max-width: 63em) {
        gap: 1.5rem;
      }

      .title {
        font-weight: 600;
        font-size: 1.8rem;

        @media only screen and (max-width: 63em) {
          // font-size: 2.2rem;
        }
      }

      .text {
        font-size: 1.4rem;
        width: 85%;

        @media only screen and (max-width: 63em) {
          width: 100%;
          // font-size: 1.65rem;
        }
      }

      .btn {
        .text {
          width: 100%;
        }
      }
    }

    .right-wrap {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      align-items: flex-start;

      @media only screen and (max-width: 63em) {
        gap: 1.5rem;
      }

      .title {
        font-weight: 600;
        font-size: 1.8rem;

        @media only screen and (max-width: 63em) {
          // font-size: 2.2rem;
          // font-size: 1.5rem;
        }
      }

      .text {
        font-size: 1.4rem;
        width: 85%;

        @media only screen and (max-width: 63em) {
          // font-size: 1.65rem;
          width: 100%;
        }
      }

      .btn {
        background-color: rgba(14, 115, 246, 0.1);

        .text {
          color: rgba(14, 115, 246, 1);
        }
      }
    }
  }
}
